import PropTypes from "prop-types";
import styled from "styled-components";
import { Container } from "styles/components/Container.styled";
import { breakpoints } from "styles/grid";

const SectionStyled = styled("section")``;

const OuterContainer = styled("div")`
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	z-index: 1;
	overflow: hidden;
	background-color: var(--dz-sys-card-background-color);
`;

const InnerContainer = styled(Container)`
	position: relative;
	z-index: 2;
	padding: 2rem 1rem;

	@media (max-width: ${breakpoints.desktop - 1}px) {
		padding: 1rem;
	}
`;

const Background = styled("div")`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

const Section = ({ background = null, children, ...props }) => {
	return (
		<OuterContainer {...props}>
			{background ? <Background>{background}</Background> : null}
			<InnerContainer>
				<SectionStyled>{children}</SectionStyled>
			</InnerContainer>
		</OuterContainer>
	);
};

Section.propTypes = {
	background: PropTypes.element,
	children: PropTypes.node.isRequired,
};

export default Section;
