// ** React Imports
import { useCallback, useEffect } from "react";

// ** Redux & Store & Actions
import { useSelector } from "react-redux";

// ** Third Party Components
import { useTranslation } from "react-i18next";
import loadable from "@loadable/component";

// ** Custom Components
import Block from "components/layout/Block";

// ** Landing Components
import Login from "../../containers/Login/Login";

const Recover = loadable(() => import("../../containers/Recover"), {
	ssr: false,
});
const Register = loadable(() => import("../../containers/Register"), {
	ssr: false,
});

// ** Styled Components
import { StyledPanelNav, StyledPanelBody } from "./styles";

// #####################################################

const topPanelLinks = [
	{
		key: "login",
		contentKey: "landingLogin",
		displayWhenActiveTab: ["login", "recover"],
	},
	{
		key: "register",
		contentKey: "landingRegister",
		displayWhenActiveTab: ["register"],
	},
];

// #####################################################

const AuthPanel = ({ activeTab, setActiveTab }) => {
	const { t } = useTranslation(["pages"]);

	const isClient = useSelector((state) => state.global.isClient);

	const language = useSelector((state) => state.global.language);

	const renderTopPanelLinks = useCallback(() => {
		return topPanelLinks.map((item) => {
			const contentKey = t(`pages:${item.contentKey}`);
			return (
				<StyledPanelNav
					onClick={() => setActiveTab(item.key)}
					className={
						item.displayWhenActiveTab.includes(activeTab) &&
						"active"
					}
					key={item.key}
					label={contentKey}
				>
					{contentKey}
				</StyledPanelNav>
			);
		});
	}, [language, activeTab]);

	useEffect(() => {
		if (isClient) {
			setTimeout(() => {
				Register.preload();
				Recover.preload();
			}, 100);
		}
	}, [isClient]);

	// #####################################################

	return (
		<>
			<Block flex>{renderTopPanelLinks()}</Block>
			<StyledPanelBody>
				{activeTab === "login" && <Login setActiveTab={setActiveTab} />}
				{activeTab === "register" && (
					<Register setActiveTab={setActiveTab} />
				)}
				{activeTab === "recover" && (
					<Recover setActiveTab={setActiveTab} />
				)}
			</StyledPanelBody>
		</>
	);
};

// #####################################################

export default AuthPanel;

// #####################################################
